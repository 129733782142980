// make array from src string

const getSrc = srcSet => {
  return srcSet ? srcSet.split(',') : []
}

// parse size from text

const getSize = data => {
  let size = 0
  try {
    if (Array.isArray(data)) {
      [ size ] = data.pop().match(/(\d+)(?=w)/g)
    } else {
      [ size ] = data.match(/(\d+)(?=px)/g)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Can't get image size " + e)
  }
  return Number(size)
}

/**
 * Returns updated image object
 * @param {object} image object created by GraphQL
 * @param {string} string with media-query
 * @returns {object}
 */

export const updateImage = imageObj => {
  const updatedImage = { ...imageObj }
  const srcSet = getSrc(imageObj.srcSet)
  const srcSetWebp = getSrc(imageObj.srcSetWebp)
  const querySize = getSize(imageObj.sizes || '')
  const largestImageSize = getSize([...srcSet])

  if (querySize < largestImageSize) {
    updatedImage.srcSet = srcSet.slice(0, -1).join(',')
    updatedImage.srcSetWebp = srcSetWebp.slice(0, -1).join(',')
  }

  return updatedImage
}
