import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import SEO from '../components/Seo'
import Service from '../components/Service/Service'
import { slugify } from '../utils/utilityFunctions'

const ServicesPage = () => (
  <StaticQuery
    query={servicesQuery}
    render={data => {
      const {
        carousel: {
          frontmatter:
            {
              overHeader,
              header
            },
          html,
        },
        seo: {
          frontmatter: {
            seo: seoInfo
          }
        },
        services: { frontmatter: { services } }
      } = data

      const clsContent = ['services-item-wrapper']
      const clsWrapper = ['top-section-text', 'wrapper']
      if (html) {
        clsWrapper.push('subheader-present')
        clsContent.push('subheader-present')
      }
      return (
        <Fragment>
          <SEO data={seoInfo} />
          <section className="services-page">
            <div className="top-section">
              <div className={clsWrapper.join(' ')}>
                <div className="top-header">
                  <div className="white-header"
                    dangerouslySetInnerHTML={{ __html: overHeader }}
                  />
                  <h1 className="green-header">{header}</h1>
                </div>
                <div className="top-sub-header"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </div>
            </div>
            <div className="service-wrapper wrapper">
              <div className={clsContent.join(' ')}>
                {services.map((service, i) => (
                  <Service
                    key={i}
                    image={service.image.childImageSharp.fluid}
                    order={++i}
                    header={service.title}
                    description={service.description}
                    redirectionButtonName={service.referenced.buttonText}
                    slug = {
                      `/services/${
                        slugify(service.referenced.redirectLink)
                      }/`
                    }
                  />
                ))}
              </div>
            </div>
          </section>
        </Fragment>
      )
    }}
  />
)

export const servicesQuery = graphql`
  query {
    carousel:markdownRemark (
      fileAbsolutePath: {regex: "/content/general-pages/services/header.md/"}
    ) {
      frontmatter {
        overHeader
        header
      }
      html
    }
    seo: markdownRemark(
      fileAbsolutePath: {regex: "/content/general-pages/services/seo-services.md/"}
    ) {
      frontmatter {
        seo {
          canonical
          title
          description
          keywords
          robots
          image {
            childImageSharp {
              original {
                src
              }
            }
          }
          imageAlt
          twitterCard
        }
      }
    }
    services: markdownRemark (
      fileAbsolutePath: {regex: "/content/general-pages/services/services.md/"}
    ) {
      frontmatter {
        services {
          title
          description
          referenced {
            redirectLink
            buttonText
          }
          image {
            childImageSharp {
              fluid(
                maxWidth: 504,
                maxHeight: 355,
                quality: 85, 
                srcSetBreakpoints: [320, 380, 504]
            ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default ServicesPage
