import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { updateImage } from '../../utils/updateImage'
import classes from './Service.module.scss'

/**
 * Service component
 **/

const Service = ({ image, order, header, description, slug, redirectionButtonName }) => {
  const cls = [classes.Service]
  if (order && !(order % 2)) {
    cls.push(classes.Right)
  }

  // removed largest image size from srcSet, if it exist
  const updatedImage = updateImage(image)
  return (
    <div className={cls.join(' ')}>
      <a
        href={slug}
        className = {classes.ServiceImage}>
        <Img
          fluid={{
            ...updatedImage,
            sizes: `(max-width: 380px) 320px, (max-width: 440px) 380px, 504px`
          }}
          loading="eager"
          alt={header}
        />
      </a>
      <Link
        to={slug}
        className={classes.ServiceNumber}>
        {`0${ order }`}
      </Link>
      <div className={classes.ServiceInfo}>
        <Link
          to={slug}
          className={classes.ServiceHeader}>
          {header}
        </Link>
        <div
          className={classes.ServiceDescription}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <Link
          to={slug}
          className={classes.ServiceLink}
        >
          {redirectionButtonName}
        </Link>
      </div>
    </div>
  )
}

export default Service
